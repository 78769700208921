import React, { useState, useMemo ,useEffect} from 'react';
import { Box } from 'grommet';
// import { graphql, useStaticQuery } from 'gatsby';
import ModalEvent from '../components/Calendar/ModalEvent';
import Month from '../components/Calendar/Month';
import Hero from '../components/Calendar/Hero';
import CalendarLayout from '../components/Calendar/Layout';
import groupEventsByMonth from '../components/Calendar/utils/groupEventsByMonth';
import { format } from 'date-fns';
import Layout from '../components/layout';
import { AXIOSLINK } from '../utils/constants';

const axios = require('axios');

type EventInfo = {
  id: string;
  cal_event_name: string;
  cal_event_date: Date;
  cal_event_link: string;
  cal_event_place: string;
};

type ModalData = {
  date: Date;
  events: EventInfo[];
};

export const CalendarPage = () => {
  const [modalData, setModalData] = useState<ModalData>();
  const [data,setData] = useState([]);
  // const { allStrapiCalendarevents } = data;
  // const { site } = useStaticQuery(SPREADSHEET_QUERY);
  //   const { limitMonthInTheFuture } = site.siteMetadata;

  //  let data = [];

  const limitMonthInTheFuture = 3;

  // const months = useMemo(
  //   () =>
  //     groupEventsByMonth(allStrapiCalendarevents.nodes, limitMonthInTheFuture),
  //   [allStrapiCalendarevents.nodes, limitMonthInTheFuture],
  // );

  const months = useMemo(
    () =>
      groupEventsByMonth(data, limitMonthInTheFuture),
    [data, limitMonthInTheFuture],
  );

  // console.log("In CalenderEvent file");
  // console.log(months);

  useEffect(()=>{
    axios
            .get(AXIOSLINK +'calendarevents')
            .then(result => {
                // console.log(result.data)
                // data = result.data;
                setData(result.data);
            })
            .catch(err => {
                console.log({ err })
                console.log('====================================')
                console.log(`error creating Page:${err}`)
                console.log('====================================')
                //   reject(new Error(`error on page creation:\n${err}`))
            })
  },[]);

  return (
    <Layout>
      <CalendarLayout>
        <Hero />

        <Box id="calendars" animation="fadeIn">
          {months.map(month => (
            <Month
              key={format(month.startDate, 'MM')}
              openModal={(datas: ModalData) => setModalData(datas)}
              {...month}
            />
          ))}
        </Box>
        {modalData && (
          <ModalEvent onClose={() => setModalData(undefined)} {...modalData} />
        )}

        {/* <GithubCorner href="https://github.com/EmaSuriano/gatsby-starter-event-calendar" /> */}
        {/* <Footer /> */}
      </CalendarLayout>
    </Layout>
  )
};

// export const query = graphql`
//   {
//     allStrapiCalendarevents {
//       nodes {
//         id
//         cal_event_place
//         cal_event_name
//         cal_event_link
//         cal_event_date
//       }
//     }
//   }
// `

export default CalendarPage;
