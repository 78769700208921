import {
  format,
  differenceInMonths,
  isBefore,
  isSameMonth,
  compareAsc,
  parse,
} from 'date-fns';
import { parseEventDate } from './parseDate';

const removeOldAndFutureEvents = (
  event: EventInfo,
  today: Date,
  monthDiff: number,
) => {
  const eventDate = parseEventDate(event.cal_event_date);

  if (!isSameMonth(eventDate, today) && isBefore(eventDate, today))
    return false;
  if (differenceInMonths(eventDate, today) >= monthDiff) return false;

  return true;
};

const groupEventsByMonth = (
  events: EventInfo[],
  monthsDifferenceThreshold: number,
): MonthInfo[] => {
  const today = new Date();

  console.log("group events by month function :  ");
  console.log(events);
  const eventsByMonthKey = events
    .filter((event) =>
      removeOldAndFutureEvents(event, today, monthsDifferenceThreshold),
    )
    .reduce((acc: { [key: string]: EventInfo[] }, event) => {
      console.log("inside reducer function");
      console.log(event);
      const dtTest = new Date(event.cal_event_date);
      const dt1 = dtTest.toISOString().split('T')[0];
      const dt2 = dt1.split('-',3);
      const dt3 = dt2[1]+"-"+dt2[0];
      console.log(dt3);
      // const dt = parse(dt1,'MM-dd-yyyy',new Date());
      // console.log(dt);
      // const monthYear = format(parse(event.cal_event_date,'MM-dd-yyyy',new Date()), 'MM-yyyy');

      const monthYear = dt3;

      const events = acc[monthYear] || [];
      return {
        ...acc,
        [monthYear]: events.concat(event),
      };
    }, {});

    console.log(eventsByMonthKey);

  const result = Object.keys(eventsByMonthKey)
    .map((monthKey) => ({
      events: eventsByMonthKey[monthKey],
      startDate: parse(`01-${monthKey}`, 'dd-MM-yyyy', 0),
    }))
    .sort((m1, m2) => compareAsc(m1.startDate, m2.startDate));

  return result;
};

export default groupEventsByMonth;
