import { getDaysInMonth, isSameDay } from 'date-fns';
import { parseEventDate } from './parseDate';

const buildDaysWithEvents = (
  startDate: Date,
  events: EventInfo[],
): ModalData[] => {
  const days = getDaysInMonth(startDate);

 

  return Array(days)
    .fill(null)
    .map((x, i) => {
      const date = new Date(
        startDate.getFullYear(),
        startDate.getMonth(),
        i + 1,
      );
     
    //  const parsetestDate = parseEventDate(events[0].cal_event_date);

    const testDate = new Date(events[0].cal_event_date);
    const testDate1 = new Date(testDate.getFullYear(),testDate.getMonth(),i+1);
    console.log(testDate1);
    console.log(testDate.getDate(),testDate.getDay());
     const testevent = isSameDay(testDate.getUTCDate(), date);
     const testevent1 = isSameDay(testDate, date);
     const testevent2 = isSameDay(testDate1, date);

      return {
        date,
        events: events.filter((event) =>{
          const testDate = new Date(event.cal_event_date);
          return (isSameDay(testDate, date))
        }
        ),
      };
    });
};

export default buildDaysWithEvents;
